import * as React from 'react';

import { FormContainer, FormRow, FormColumn, FormRowHeader } from '@oysterjs/ui/Form/builder';
import { TextInput } from '@oysterjs/ui/Form/text';
import { Select } from '@oysterjs/ui/Form/select';
import { ElectronicsFormData } from './collect';
import { ProductType } from '@oysterjs/types';
import { ElectronicsProduct, ElectronicsType } from '@oysterjs/types';

interface QuickQuoteProps {
  productType: ProductType;
  onPriceUpdate: (value: number, data: ElectronicsFormData) => void;
}

export const ElectronicsQuickQuote = (props: React.PropsWithChildren<QuickQuoteProps>) => {
  const [formData, setFormData] = React.useState<ElectronicsFormData>({
    ProductType: props.productType,
    Price: '',
    Details: {} as ElectronicsProduct
  });

  React.useEffect(() => {
    setData(() => ({
      ProductType: props.productType,
      Price: '',
      Details: {} as ElectronicsProduct
    }));
  }, [props.productType]);

  const setData = (fn: (prev: ElectronicsFormData) => ElectronicsFormData) => {
    setFormData((prev) => {
      const next = fn(prev);
      props.onPriceUpdate(getPrice(next), next);
      return next;
    });
  };

  const getPrice = (data: ElectronicsFormData): number => {
    if (!data.ZipCode || !data.Price || !(data.Details.Manufacturer || data.Details.Type)) {
      return 0;
    }
    const amount = parseFloat(data.Price);
    if (!amount || isNaN(amount)) {
      return 0;
    }
    return Math.max(0.11 * amount, 25);
  };

  return (
    <FormContainer>
      <FormRowHeader
        title="Residential Zip Code"
        description="Enter the zip code in which you live."
      />
      <FormRow>
        <FormColumn>
          <TextInput
            style={{ maxWidth: '200px' }}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData((prev) => ({ ...prev, ZipCode: value }));
            }}
            value={formData.ZipCode}
            inputMode="numeric"
            autoComplete="postal-code"
          />
        </FormColumn>
      </FormRow>
      <FormRowHeader
        title={`What type of ${props.productType} is this?`}
        description={`Choose the type of ${props.productType} you'd like to insure.`}
      />
      <FormRow>
        <FormColumn>
          {props.productType === ProductType.phone && (
            <Select
              options={[
                { value: '' },
                { value: 'Apple' },
                { value: 'Google' },
                { value: 'Huawei' },
                { value: 'LG' },
                { value: 'Motorola' },
                { value: 'Oppo' },
                { value: 'Samsung' },
                { value: 'Xiaomi' },
                { value: 'Other' }
              ]}
              style={{ maxWidth: '200px' }}
              onChange={(value) => {
                setData((prev) => ({ ...prev, Details: { ...prev.Details, Manufacturer: value } }));
              }}
              value={formData.Details.Manufacturer}
            />
          )}
          {props.productType === ProductType.electronics && (
            <Select
              options={[
                { value: '' },
                { displayValue: 'Laptop', value: ElectronicsType.laptop },
                { displayValue: 'Tablet', value: ElectronicsType.tablet },
                { displayValue: 'eReader', value: ElectronicsType.eReaderKindle },
                { displayValue: 'Gaming console', value: ElectronicsType.gamingSystem },
                { displayValue: 'Camera or Other devices', value: ElectronicsType.smallElectronics }
              ]}
              style={{ maxWidth: '200px' }}
              onChange={(value) => {
                setData((prev) => ({ ...prev, Details: { ...prev.Details, Type: value } }));
              }}
              value={formData.Details.Type}
            />
          )}
        </FormColumn>
      </FormRow>
      <FormRowHeader
        title="Item Value"
        description={`Enter the purchase price of your ${props.productType}.`}
      />
      <FormRow>
        <FormColumn>
          <TextInput
            currency
            style={{ maxWidth: '200px' }}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData((prev) => ({ ...prev, Price: value }));
            }}
            value={formData.Price}
            inputMode="decimal"
          />
        </FormColumn>
      </FormRow>
    </FormContainer>
  );
};
