import * as React from 'react';

import { FormContainer, FormRow, FormColumn, FormRowHeader } from '@oysterjs/ui/Form/builder';
import { TextInput } from '@oysterjs/ui/Form/text';
import { Select } from '@oysterjs/ui/Form/select';
import { BikeFormData } from './collect';
import { BikeProduct } from '@oysterjs/types';

interface QuickQuoteProps {
  onPriceUpdate: (value: number, data: BikeFormData, err?: Error) => void;
}

export const BikeQuickQuote = (props: React.PropsWithChildren<QuickQuoteProps>) => {
  const [formData, setFormData] = React.useState<BikeFormData>({
    Price: '',
    Details: {} as BikeProduct
  });

  const setData = (fn: (prev: BikeFormData) => BikeFormData) => {
    setFormData((prev) => {
      const next = fn(prev);
      props.onPriceUpdate(getPrice(next), next);
      return next;
    });
  };

  const getPrice = (data: BikeFormData): number => {
    if (!data.ZipCode || !data.Price) {
      return 0;
    }
    const amount = parseFloat(data.Price);
    if (!amount || isNaN(amount)) {
      return 0;
    }
    return Math.max((data.Details.PowerSource ? 0.071 : 0.062) * amount, 100);
  };

  return (
    <FormContainer>
      <FormRowHeader
        title="Residential Zip Code"
        description="Enter the zip code in which you live and/or ride your bike."
      />
      <FormRow>
        <FormColumn>
          <TextInput
            style={{ maxWidth: '200px' }}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData((prev) => ({ ...prev, ZipCode: value }));
            }}
            value={formData.ZipCode}
            inputMode="numeric"
            autoComplete="postal-code"
          />
        </FormColumn>
      </FormRow>
      <FormRowHeader
        title="Bike Value"
        description="Enter the total value of the bike and parts you'd like to insure."
      />
      <FormRow>
        <FormColumn>
          <TextInput
            currency
            style={{ maxWidth: '200px' }}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData((prev) => ({ ...prev, Price: value }));
            }}
            value={formData.Price}
            inputMode="decimal"
          />
        </FormColumn>
      </FormRow>
      <FormRowHeader
        title="Is this an e-bike?"
        description="If this is an e-bike, specify the e-bike class."
      />
      <FormRow>
        <FormColumn>
          <Select
            options={[
              { displayValue: 'No', value: '' },
              { displayValue: 'Yes, Class I', value: 'pedal_assist_20_mph' },
              { displayValue: 'Yes, Class II', value: 'throttle_assist_20_mph' },
              { displayValue: 'Yes, Class III', value: 'pedal_assist_28_mph' }
            ]}
            style={{ maxWidth: '200px' }}
            value={formData.Details.PowerSource || ''}
            onChange={(value) =>
              setData((prev) => ({
                ...prev,
                Details: { ...prev.Details, PowerSource: value }
              }))
            }
          />
        </FormColumn>
      </FormRow>
    </FormContainer>
  );
};
